<template>
  <FilterForm
    :formData="filterData"
    :filters="filters"
    @submit="handleSubmit"
    @reset="handleReset"
  />
  <div class="filter-block">
    <el-button
        v-if="buttons.includes('分配按钮')"
      size="small"
      type="primary"
      @click="handleDistribution"
    >分配给</el-button>
    <el-button
        type="primary"
        size="small"
        @click="handlePublicPool"
    >移入公海</el-button>
    <el-button
        type="primary"
        size="small"
        @click="handleRubbish"
    >放入垃圾库</el-button>
    <el-button
      size="small"
      icon="el-icon-download"
      @click="downloadT"
    >下载模版</el-button>

    <el-upload
      class="upload-demo"
      action="/admin/api/v1/customers/import/source-pool"
      :headers="{
        Authorization: token
      }"
      :on-change="handleChange"
      :on-success="handleSuccess"
    >
      <el-button
        type="primary"
        size="small"
        icon="el-icon-upload"
      >上传文件</el-button>
    </el-upload>

  </div>
  <ResourceCustomerTable
    :tableColumn="tableColumn"
    :tableData="tableData"
    :selection="true"
    @selection="handleSelectionChange"
    @followRecords="followRecords"
  />
  <ResourcePagination
    :total="total"
    @currentChange="currentChange"
    @sizeChange="sizeChange"
  />
  <DialogFollowRecords
    :isShowDialog="isShowFollow"
    :width="500"
    @close="handleFollowClose"
    :list="followList"
  />
  <DistributionDialog
    title="分配给"
    :isShowDialog="isShowDialog"
    :forms="forms"
    :formData="formData"
    :width="500"
    @submit="handleDistributionSubmit"
    @close="handleClose"
  />
</template>

<script>
import FilterForm from "@/components/filter";
import ResourceCustomerTable from "@/components/table";
import ResourcePagination from "@/components/pagination";
import { computed, onMounted, reactive, ref } from "vue";
import { filterArr, tableColumn } from "./index.js";
import { useStore } from "vuex";
import { reset } from "@/until";
import {
  ElButton,
  ElMessage,
  ElOption,
  ElSelect,
  ElUpload,
} from "element-plus";
import base from "@/api/base";
import { getDistributeUsers } from "@/api/method/system";
import {distribute, movetoPublicPool, movetoRubbish} from "@/api/method/customer";
import DistributionDialog from "@/components/dialog-form";
import DialogFollowRecords from "@/components/dialog-followRecords";

export default {
  name: "ResourceCustomer",
  props: [],
  components: {
    FilterForm,
    ResourceCustomerTable,
    ResourcePagination,
    [ElUpload.name]: ElUpload,
    [ElButton.name]: ElButton,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    DistributionDialog,
    DialogFollowRecords,
  },

  setup() {
    const { dispatch, state } = useStore();
    const filterData = reactive({
      name: null,
      mobile: null,
      date: null,
    });

    const filters = ref(filterArr);

    const buttons = ref(localStorage.getItem("buttons").split(","));
    onMounted(() => {
      getList();
    });
    const pagination = reactive({
      page: 1,
      size: 10,
    });
    const tableData = computed(
      () => (state.resourceList && state.resourceList.records) || []
    );
    const total = computed(
      () => state.resourceList && state.resourceList.total
    );
    const getList = () => {
      customerIds.value = [];
      const { date, ...params } = filterData;
      const startTime = date && date[0];
      const endTime = date && date[1];

      dispatch("resourceList", {
        ...pagination,
        startTime,
        endTime,
        ...params,
      });
    };

    const handleSubmit = () => getList();
    const handleReset = () => {
      reset(filterData);
      filterData.customerAttr = [];
      getList();
    };
    const customerIds = ref([]);
    const handleSelectionChange = (arr) => {
      customerIds.value = arr.map((item) => item.id);
    };
    const importTo = ref(null);
    const options = reactive([
      {
        label: "公海",
        val: 3,
      },
      {
        label: "重要客户",
        val: 2,
      },
    ]);
    const downloadT = async () => {
      window.location.href = base.downloadTemplate;
    };

    const token = ref(`Bearer ${localStorage.getItem("token")}`);
    const currentChange = (page) => {
      pagination.page = page;
      getList();
    };
    const sizeChange = (size) => {
      pagination.size = size;
      getList();
    };

    const handleDistribution = async () => {
      if (!customerIds.value.length) {
        ElMessage.warning("请勾选客户后操作");
        return;
      }
      isShowDialog.value = true;
      const res = await getDistributeUsers();

      forms[0][0].option = res.data.map((item) => ({
        label: item.nickname,
        val: item.id,
      }));
    };
    /**分配给弹窗 */
    const isShowDialog = ref(false);
    const handleClose = () => (isShowDialog.value = false);
    const forms = reactive([
      [
        {
          label: "分配给",
          type: "select",
          multiple: true,
          attr: "userId",
          placeholder: "请选择销售人员",
          column: 24,
          labelWidth: "120px",
          option: [],
        },
      ],
    ]);
    const formData = reactive({
      userId: null,
    });
    const handleDistributionSubmit = async () => {
      const res = await distribute({
        userId: formData.userId,
        customerIds: customerIds.value,
      });

      if (res.code == 200) {
        ElMessage.success("分配成功！");
        getList();
        handleClose();
      } else {
        ElMessage.error(res.msg);
      }
    };
    const handlePublicPool = async () => {
      if (!customerIds.value.length) {
        ElMessage.warning("请勾选客户后操作");
        return;
      }
      const res = await movetoPublicPool(customerIds.value);

      if (res.code == 200) {
        ElMessage.success("移入公海成功！");

        getList();
      } else {
        ElMessage.error(res.msg);
      }
    };

    const handleRubbish = async () => {

      if (!customerIds.value.length) {
        ElMessage.warning("请勾选客户后操作");
        return;
      }

      const res = await movetoRubbish(customerIds.value);
      if (res.code == 200) {
        getList()
      }
    };

    const handleSuccess = (...params) => {
      console.log(params)
      window.location.reload();
    };
    const isShowFollow = ref(false);
    const followList = ref([]);

    const followRecords = (row) => {
      isShowFollow.value = true;
      followList.value = row.followRecords;
    };
    const handleFollowClose = () => (isShowFollow.value = false);

    return {
      filters,
      filterData,
      handleSubmit,
      handleReset,
      tableData,
      tableColumn,
      handleSelectionChange,
      downloadT,
      token,
      importTo,
      options,
      total,
      currentChange,
      sizeChange,
      isShowDialog,
      handleClose,
      forms,
      formData,
      buttons,
      handleDistributionSubmit,
      handleDistribution,
      handlePublicPool,
      handleRubbish,
      handleSuccess,
      isShowFollow,
      handleFollowClose,
      followRecords,
      followList,
    };
  },
};
</script>

<style lang="scss" scoped>
.filter-block {
  display: flex;
  margin-bottom: 20px;
  justify-content: flex-end;
}
.select {
  margin: 0 20px;
}
</style>