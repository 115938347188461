export const filterArr = [
	[
		{
			label: '姓名',
			type: 'input',
			attr: 'name',
			placeholder: '请输入姓名',
			column: 4,
			labelWidth: '48px',
		},
		{
			label: '手机号',
			type: 'input',
			attr: 'mobile',
			placeholder: '请输入手机号',
			column: 6,
			labelWidth: '96px',
		},
		{
			label: '注册时间',
			type: 'dateRange',
			valueFormat:"YYYY-MM-DD",
			attr: 'date',
			placeholder: '请选择',
			column: 8,
			labelWidth: '96px',
		},
		{ type: 'button', column: 6 },
	],
]
export const tableColumn = [
	{
		label: '用户名',
		prop: 'name',
		width: '120px',
		fixed: true,
	},
	{
		label: 'ID',
		prop: 'id',
		width: '80px',
	},
	{
		label: '年龄',
		prop: 'age',
		width: '80px',
	},
	{
		label: '性别',
		prop: 'sex',
		width: '80px',
		isCustomize: true,
		tableType: 'sexNumber',
	},
	{
		label: '额度',
		prop: 'loanLimit',
		width: '80px',
	},
	{
		label: '跟进人',
		prop: 'username',
		width: '120px',
	},
	{
		label: '星级',
		prop: 'starStatus',
		width: '80px',
		isCustomize: true,
		tableType: 'starStatus',
	},
	{
		label: '跟进状态',
		prop: 'status',
		width: '100px',
		isCustomize: true,
		tableType: 'followUpStatus',
	},
	{
		label: '客户来源',
		prop: 'channelName',
		width: '100px'
	},
	{
		label: '跟进情况备注(点击查看更多)',
		prop: 'followRecords',
		width: '250px',
		isCustomize: true,
		tableType: 'followRecords',
	},
	{
		label: '客户条件(备注)',
		prop: 'remark',
		width: '250px',
	},
	{
		label: '客户属性',
		prop: 'clientProperty',
		width: '250px',
	},
	{
		label: '跟进次数',
		prop: 'followNum',
		width: '100px',
	},
	{
		label: '分配时间',
		prop: 'distributionTime',
		width: '200px',
	},
	{
		label: '再分配时间',
		prop: 'redistributionTime',
		width: '200px',
	},
	{
		label: '最后跟进时间',
		prop: 'lastFollowTime',
		width: '200px',
	},
	{
		label: '申请时间',
		prop: 'createTime',
		width: '200px',
	},
]
